<script setup lang="ts">
import { ref, computed, watchEffect, watch } from "vue";
import * as yup from "yup";
import { Form } from "vee-validate";
import { useNotification } from "@kyvg/vue3-notification";
import { useUiStore } from "@/stores/ui";
import { useMerchantStore } from "@/stores/merchant";
import { useWalletStore } from "@/stores/wallet";

import type { ApiResponse } from "@/types/api"; // Assuming an ApiResponse type exists

const totalPaymentFee = ref({
  topUpFee: 0,
  withdrawFee: 0,
});

const profile = computed(() => useMerchantStore().profile);
const balance = computed(() => useUiStore().walletAmount);
const isTopup = computed(() => useUiStore().isTopup);
const title = computed(() => (isTopup.value ? " Beli Kredit" : "Pencairan"));
const { $api: api } = useNuxtApp();
const { notify: notif } = useNotification();
const isModalTopupShow = computed(() => useUiStore().isModalTopupShow);
const amounts = ["Rp100.000", "Rp200.000", "Rp500.000", "Rp1.000.000"];
const paymentFee = computed(() =>
  isTopup.value
    ? totalPaymentFee.value.topUpFee
    : totalPaymentFee.value.withdrawFee,
);
const customForm = ref();
const amount = ref("0");
const totalAmount = ref(0);
const schema = yup.object().shape({
  "Masukan total kredit": yup
    .string()
    .test("amount-validation", `Minimal beli kredit Rp10.000`, (value) => {
      if (value) {
        const newValue = value.replaceAll("Rp", "").replaceAll(".", "");
        return Number(newValue) >= 10000;
      }
    }),
  "Masukan total pengambilan": yup
    .string()
    .test("amount-validation", `Minimal pengambilan Rp100.000`, (value) => {
      if (value) {
        const newValue = value.replaceAll("Rp", "").replaceAll(".", "");
        return Number(newValue) >= 100000;
      }
    })
    .test(
      "balance-validation",
      `Total kredit tidak mencukupi untuk dicairkan`,
      (value) => {
        if (value) {
          const newValue = value.replaceAll("Rp", "").replaceAll(".", "");
          return (
            Number(newValue) + Number(paymentFee.value) <= Number(balance.value)
          );
        }
      },
    ),
});
const disabledButton = computed(() => {
  const amountInt = currencyStringToInt(amount.value);
  if (title.value === "Pencairan") {
    return amountInt < 10000 || amountInt > balance.value || amountInt < 100000;
  } else {
    return amountInt < 10000;
  }
});

const getBankFee = async () => {
  const {
    data: result,
    status,
  }: ApiResponse<{ top_up_fee: number; withdraw_fee: number }> =
    await api.payment.getBankFee();
  if (status === "success") {
    paymentFee.value = {
      topUpFee: result?.top_up_fee || 0,
      withdrawFee: result?.withdraw_fee || 0,
    };
  }
};

const resetValue = () => {
  amount.value = "0";
  totalAmount.value = 0;
  if (customForm.value) {
    customForm.value.resetForm();
    customForm.value.setFieldValue("Masukan total kredit", "0");
  }
};

const handleClose = () => {
  useUiStore().set({ isModalTopupShow: false });
  resetValue();
};

const submitFormModal = async () => {
  const payload = {
    jumlah_pencairan: amount,
    biaya_layanan: paymentFee,
    total: totalAmount,
  };

  if (title.value === "Pencairan") {
    useWalletStore().set({ pencairanDana: payload });
    useUiStore().set({ isModalChooseBank: true, isModalTopupShow: false });
  } else {
    const { data: result, status } = await api.wallet.postTopup({
      merchant_id: profile.value.merchant_id,
      amount: currencyStringToInt(amount.value),
      service_fee: paymentFee.value,
      redirect_url: window.location.href,
    });
    if (status === "success") {
      // notif({
      //   title: "Beli Kredit berhasil",
      //   type: "success",
      // });
      useUiStore().set({ isModalTopupShow: false });
      window.location.href = result.url;
    } else if (status !== "success" && result) {
      notif({
        title: result,
        type: "error",
      });
    }
  }
};
const setAmount = (value: string) => {
  amount.value = value;
  customForm.value.setFieldValue("Masukan total kredit", value);
};
watchEffect(() => {
  if (amount.value) {
    const cleanAmount = amount.value.replace("Rp", "").replace(/\./g, "");
    totalAmount.value = parseInt(cleanAmount, 10) + paymentFee.value;
  }
});

getBankFee();
</script>
<template>
  <Modal
    id="modal-wallet"
    :is-modal-show="isModalTopupShow"
    :title="title"
    :save-text="title"
    size="lg"
    without-cancel
    without-border
    :disable-button="disabledButton"
    @close-modal="handleClose()"
    @save-action="submitFormModal()"
  >
    <template #subheader>
      <p class="text-xs">
        Saldo Saat ini
        <span class="font-bold text-green-500"
          >Rp{{ numberFormat(balance) }}</span
        >
      </p>
    </template>
    <template #body>
      <Form
        ref="customForm"
        class="grid w-full gap-6"
        :validation-schema="schema"
      >
        <FormsInput
          id="topup-amount"
          v-model="amount"
          :label="`Masukan total ${
            title === 'Pencairan' ? 'pengambilan' : 'kredit'
          }`"
          placeholder="Rp10.000"
          :label-info="`Minimal ${
            title === 'Pencairan'
              ? 'pengambilan Rp100.000'
              : 'beli kredit Rp10.000'
          }`"
          only-number
          :formatter="numberFormat"
        >
          <template #beforeIcon>
            <span class="text-sm text-gray-500">Rp</span>
          </template>
        </FormsInput>
        <div>
          <label class="mb-2 block text-xs text-gray-500">
            Atau pilih dibawah ini
          </label>
          <div class="flex flex-row gap-5">
            <button
              v-for="(am, index) in amounts"
              :id="`wallet-amount-${index + 1}`"
              :key="am"
              type="button"
              class="badge rounded-md bg-gray-100 px-3 py-[2px] !text-sm font-medium"
              @click="setAmount(am)"
            >
              {{ am }}
            </button>
          </div>
        </div>
      </Form>

      <div class="mt-6 rounded-2xl bg-[#EBF5FF] p-4 text-sm">
        <div class="flex flex-row justify-between">
          <div class="text-[#6B7280]">
            {{ title === "Pencairan" ? "Jumlah pencairan" : "Total kredit" }}
          </div>
          <div>Rp{{ amount }}</div>
        </div>
        <div class="mt-2 flex flex-row justify-between">
          <div class="text-[#6B7280]">Biaya Layanan</div>
          <div v-if="paymentFee > 0">Rp{{ numberFormat(paymentFee) }}</div>
          <div v-else>FREE</div>
        </div>
        <div class="border-#D1D5DB mt-2 border"></div>
        <div class="mt-2 flex flex-row justify-between">
          <div>Total</div>
          <div>Rp{{ numberFormat(totalAmount) }}</div>
        </div>
      </div>
    </template>
  </Modal>
</template>
